import { GLOBAL } from 'saddlebag-browser';

export const MARK_NAMES = {
  START_LOADING: 'START_LOADING',
  CR_LOADING_MORE: 'CR_LOADING_MORE',
  CR_STOP_LOADING: 'CR_STOP_LOADING',
  APP_INIT: 'APP_INIT',
};

const getPerformance = (): Performance | undefined => {
  const $window = GLOBAL.getWindow();

  return $window.performance;
};

const markAlreadyExists = (markName: string) => {
  const performance = getPerformance();

  if (!performance?.getEntriesByName) return false;

  return (performance.getEntriesByName(markName) || []).length > 0;
};

export const perfMark = (markName: string) => {
  const performance = getPerformance();

  if (!markAlreadyExists(markName) && performance?.mark) {
    performance.mark(markName);
  }
};

export const perfMeasure = (
  measureName: string,
  startMark: string,
  endMark: string,
): PerformanceMeasure | undefined => {
  const performance = getPerformance();

  if (markAlreadyExists(startMark) && markAlreadyExists(endMark)) {
    return performance?.measure && performance.measure(measureName, startMark, endMark);
  }

  return undefined;
};

export const perfClearMarks = (markNames: string[]) => {
  const performance = getPerformance();

  markNames.forEach((markName) => {
    performance?.clearMarks && performance.clearMarks(markName);
  });
};
